// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var Note = {};

var Button = {
  Note: Note
};

var initialState = {
  userState: /* HasNotInteraced */0,
  synthState: /* IsNotPlaying */1
};

exports.initialState = initialState;
exports.Button = Button;
/* No side effect */
