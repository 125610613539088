// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function NoteListItem(Props) {
  var onClick = Props.onClick;
  var note = Props.note;
  var isActive = Props.isActive;
  var classNames = "p-1 h-full flex items-center justify-center w-full cursor-pointer select-none font-medium border-r border-b" + (
    isActive ? " bg-green-100" : " font-medium md:hover:bg-green-50"
  );
  return React.createElement("button", {
              className: classNames,
              onClick: onClick
            }, note);
}

var make = NoteListItem;

exports.make = make;
/* react Not a pure module */
