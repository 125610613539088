// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function Note(Props) {
  var active = Props.active;
  var note = Props.note;
  var nodeRef = Props.nodeRef;
  return React.createElement("div", {
              ref: nodeRef,
              className: "flex text-lg tracking-widest w-note snap-center items-center font-bold justify-center flex-shrink-0 my-2 py-4 border-r border-dashed border-gray-400 select-none " + (
                active ? "text-accentlight" : "text-accent"
              ),
              id: note
            }, note);
}

var make = Note;

exports.make = make;
/* react Not a pure module */
