// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var baseNotes = [
  "A1",
  "A#1",
  "B1",
  "C2",
  "C#2",
  "D2",
  "D#2",
  "E2",
  "F2",
  "F#2",
  "G2",
  "G#2",
  "A2",
  "A#2",
  "B2",
  "C3",
  "C#3",
  "D3",
  "D#3",
  "E3",
  "F3",
  "F#3",
  "G3",
  "G#3",
  "A3",
  "A#3",
  "B3",
  "C4",
  "C#4",
  "D4",
  "D#4",
  "E4",
  "F4",
  "F#4",
  "G4",
  "G#4",
  "A4",
  "A#4",
  "B4",
  "C5"
];

var standard_value = [
  [
    "E2",
    "E2"
  ],
  [
    "A2",
    "A2"
  ],
  [
    "D3",
    "D3"
  ],
  [
    "G3",
    "G3"
  ],
  [
    "B3",
    "B3"
  ],
  [
    "E4",
    "E4"
  ]
];

var standard = {
  label: "Standard",
  value: standard_value
};

var halfStepDown_value = [
  [
    "E2",
    "D#2"
  ],
  [
    "A2",
    "G#2"
  ],
  [
    "D3",
    "C#3"
  ],
  [
    "G3",
    "F#3"
  ],
  [
    "B3",
    "A#3"
  ],
  [
    "E4",
    "D#4"
  ]
];

var halfStepDown = {
  label: "Half step down",
  value: halfStepDown_value
};

var fullstepDown_value = [
  [
    "E2",
    "D2"
  ],
  [
    "A2",
    "G2"
  ],
  [
    "D3",
    "C3"
  ],
  [
    "G3",
    "F3"
  ],
  [
    "B3",
    "A3"
  ],
  [
    "E4",
    "D4"
  ]
];

var fullstepDown = {
  label: "Full step down",
  value: fullstepDown_value
};

var dropD_value = [
  [
    "E2",
    "D2"
  ],
  [
    "A2",
    "A2"
  ],
  [
    "D3",
    "D3"
  ],
  [
    "G3",
    "G3"
  ],
  [
    "B3",
    "B3"
  ],
  [
    "E4",
    "E4"
  ]
];

var dropD = {
  label: "Drop D",
  value: dropD_value
};

var doubleDropD_value = [
  [
    "E2",
    "D2"
  ],
  [
    "A2",
    "A2"
  ],
  [
    "D3",
    "D3"
  ],
  [
    "G3",
    "G3"
  ],
  [
    "B3",
    "B3"
  ],
  [
    "E4",
    "D4"
  ]
];

var doubleDropD = {
  label: "Double drop D",
  value: doubleDropD_value
};

var dMinor_value = [
  [
    "E2",
    "D2"
  ],
  [
    "A2",
    "A2"
  ],
  [
    "D3",
    "D3"
  ],
  [
    "G3",
    "F3"
  ],
  [
    "B3",
    "A3"
  ],
  [
    "E4",
    "D4"
  ]
];

var dMinor = {
  label: "D minor",
  value: dMinor_value
};

var openG_value = [
  [
    "E2",
    "D2"
  ],
  [
    "A2",
    "G2"
  ],
  [
    "D3",
    "D3"
  ],
  [
    "G3",
    "G3"
  ],
  [
    "B3",
    "B3"
  ],
  [
    "E4",
    "D4"
  ]
];

var openG = {
  label: "Open G",
  value: openG_value
};

var openD_value = [
  [
    "E2",
    "D2"
  ],
  [
    "A2",
    "A2"
  ],
  [
    "D3",
    "D3"
  ],
  [
    "G3",
    "F#3"
  ],
  [
    "B3",
    "A3"
  ],
  [
    "E4",
    "D4"
  ]
];

var openD = {
  label: "Open D",
  value: openD_value
};

var openC_value = [
  [
    "E2",
    "C2"
  ],
  [
    "A2",
    "G2"
  ],
  [
    "D3",
    "C3"
  ],
  [
    "G3",
    "G3"
  ],
  [
    "B3",
    "C4"
  ],
  [
    "E4",
    "E4"
  ]
];

var openC = {
  label: "Open C",
  value: openC_value
};

var tunings = [
  standard,
  fullstepDown,
  dropD,
  doubleDropD,
  dMinor,
  openG,
  openD,
  openC
];

var Tuning = {
  standard: standard,
  halfStepDown: halfStepDown,
  fullstepDown: fullstepDown,
  dropD: dropD,
  doubleDropD: doubleDropD,
  dMinor: dMinor,
  openG: openG,
  openD: openD,
  openC: openC,
  tunings: tunings
};

exports.baseNotes = baseNotes;
exports.Tuning = Tuning;
/* No side effect */
