// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Button = require("./components/Button.bs.js");
var Constants = require("./Constants.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ChangeTuning = require("./components/ChangeTuning.bs.js");
var NoteListItem = require("./NoteListItem.bs.js");
var Fa = require("react-icons/fa");

function makeStringNoteMap(param) {
  return Belt_Array.map(Constants.Tuning.standard.value, (function (param) {
                var gString = param[0];
                return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.getIndexBy(Constants.baseNotes, (function (n) {
                                      return gString === n;
                                    })), (function (index) {
                                  return [
                                          gString,
                                          Belt_Array.reverse(Constants.baseNotes.slice(index - 5 | 0, index + 6 | 0))
                                        ];
                                })), [
                            gString,
                            []
                          ]);
              }));
}

function Basic(Props) {
  var onPlayNote = Props.onPlayNote;
  var onUnmount = Props.onUnmount;
  var synthState = Props.synthState;
  var match = React.useState(function () {
        return Constants.Tuning.standard.value;
      });
  var setActiveTuning = match[1];
  var activeTuning = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setCurrentlyPlayingString = match$1[1];
  var currentlyPlayingString = match$1[0];
  var stringNoteMap = React.useMemo((function () {
          return makeStringNoteMap(undefined);
        }), []);
  var getIsActiveNote = function (gString, note) {
    return Belt_Option.isSome(Belt_Array.getBy(activeTuning, (function (param) {
                      if (param[0] === gString) {
                        return param[1] === note;
                      } else {
                        return false;
                      }
                    })));
  };
  React.useEffect((function () {
          return (function (param) {
                    return Curry._1(onUnmount, undefined);
                  });
        }), []);
  var onChangeTuning = function (tuning) {
    return Curry._1(setActiveTuning, (function (param) {
                  return tuning.value;
                }));
  };
  return React.createElement("div", {
              className: "w-full h-full flex flex-col mx-auto"
            }, React.createElement("div", {
                  className: "flex flex-col gap-4 md:flex-row md:justify-between w-full md:items-center px-8 md:pt-6 py-4"
                }, React.createElement("h1", {
                      className: "text-3xl font-bold"
                    }, "eztuner.app"), React.createElement("div", {
                      className: "w-full md:max-w-sm pt-2"
                    }, React.createElement(ChangeTuning.make, {
                          onChangeTuning: onChangeTuning
                        }))), React.createElement("div", {
                  className: "grid grid-cols-6 w-full justify-items-center pb-1 pt-4"
                }, Belt_Array.map(Constants.Tuning.standard.value, (function (param) {
                        var s = param[0];
                        return React.createElement("div", {
                                    key: s,
                                    className: "select-none text-sm text-gray-500 last:lowercase"
                                  }, s.substring(0, 1));
                      }))), React.createElement("div", {
                  className: "grid grid-cols-6 w-full border-l border-t h-full"
                }, Belt_Array.map(stringNoteMap, (function (param) {
                        var s = param[0];
                        return React.createElement("div", {
                                    key: s,
                                    className: "flex flex-col items-center h-full"
                                  }, Belt_Array.map(param[1], (function (n) {
                                          return React.createElement(NoteListItem.make, {
                                                      onClick: (function (param) {
                                                          return Curry._1(setActiveTuning, (function (currentTuning) {
                                                                        return Belt_Array.map(currentTuning, (function (param) {
                                                                                      var s$1 = param[0];
                                                                                      if (s$1 === s) {
                                                                                        return [
                                                                                                s$1,
                                                                                                n
                                                                                              ];
                                                                                      } else {
                                                                                        return [
                                                                                                s$1,
                                                                                                param[1]
                                                                                              ];
                                                                                      }
                                                                                    }));
                                                                      }));
                                                        }),
                                                      note: n,
                                                      isActive: getIsActiveNote(s, n),
                                                      key: n
                                                    });
                                        })));
                      }))), React.createElement("div", {
                  className: "grid grid-cols-6 w-full justify-items-stretch"
                }, Belt_Array.map(Constants.Tuning.standard.value, (function (param) {
                        var s = param[0];
                        return React.createElement(Button.Base.make, {
                                    children: React.createElement("span", {
                                          className: "w-3"
                                        }, synthState === /* IsPlaying */0 && currentlyPlayingString === s ? React.createElement(Fa.FaStop, {
                                                size: "18"
                                              }) : React.createElement(Fa.FaPlay, {
                                                size: "18"
                                              })),
                                    onClick: (function (param) {
                                        Belt_Option.map(Belt_Array.getBy(activeTuning, (function (s$1) {
                                                    return s$1[0] === s;
                                                  })), (function (str) {
                                                Curry._1(setCurrentlyPlayingString, (function (param) {
                                                        return s;
                                                      }));
                                                return Curry._1(onPlayNote, str[1]);
                                              }));
                                        
                                      }),
                                    isActive: synthState === /* IsPlaying */0 && currentlyPlayingString === s,
                                    key: s
                                  });
                      }))));
}

var make = Basic;

exports.makeStringNoteMap = makeStringNoteMap;
exports.make = make;
/* react Not a pure module */
