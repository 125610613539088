// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function Layout(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: "flex flex-grow relative w-screen flex-col h-screen"
            }, children);
}

var make = Layout;

exports.make = make;
/* react Not a pure module */
