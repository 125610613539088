// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function Button$Base(Props) {
  var children = Props.children;
  var onClick = Props.onClick;
  var onMouseDown = Props.onMouseDown;
  return React.createElement("button", {
              className: " flex items-center justify-center p-1 tracking-wide font-bold border-r border-b text-xl cursor-pointer hover:bg-gray-100 py-6",
              onClick: onClick,
              onMouseDown: (function (param) {
                  Belt_Option.map(onMouseDown, (function (fn) {
                          return fn;
                        }));
                  
                })
            }, children);
}

var Base = {
  make: Button$Base
};

function Button$PlayNote(Props) {
  var children = Props.children;
  var onClick = Props.onClick;
  return React.createElement("button", {
              className: "button-transition tracking-widest px-6 py-2 bg-accent font-bold text-accentlight border-dark border-4 text-lg w-full cursor-pointer",
              onClick: onClick
            }, children);
}

var PlayNote = {
  make: Button$PlayNote
};

function Button$Unmute(Props) {
  var children = Props.children;
  var onClick = Props.onClick;
  var onMouseDown = Props.onMouseDown;
  return React.createElement("button", {
              className: "min-w-[8rem] button-transition px-6 py-2 bg-accent tracking-widest font-bold text-current border-dark border-4 text-lg cursor-pointer",
              onClick: onClick,
              onMouseDown: (function (param) {
                  Belt_Option.map(onMouseDown, (function (fn) {
                          return fn;
                        }));
                  
                })
            }, children);
}

var Unmute = {
  make: Button$Unmute
};

exports.Base = Base;
exports.PlayNote = PlayNote;
exports.Unmute = Unmute;
/* react Not a pure module */
