// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Tone = require("tone");

var initSynth = Tone.start().then(function (param) {
      
    });

function startSynth(synth) {
  synth.connect(Tone.getDestination());
  
}

function stopSynth(synth) {
  synth.disconnect(Tone.getDestination());
  
}

function triggerNote(synth, note) {
  synth.triggerAttack(note);
  
}

function releaseNote(synth) {
  synth.triggerRelease();
  
}

exports.initSynth = initSynth;
exports.startSynth = startSynth;
exports.stopSynth = stopSynth;
exports.triggerNote = triggerNote;
exports.releaseNote = releaseNote;
/* initSynth Not a pure module */
