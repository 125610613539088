// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Free = require("./Free.bs.js");
var Tone = require("./bindings/Tone.bs.js");
var Tone$1 = require("tone");
var Basic = require("./Basic.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var State = require("./State.bs.js");
var React = require("react");
var Layout = require("./components/Layout.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

var synth = new Tone$1.Synth();

function App(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = React.useState(function () {
        return State.initialState;
      });
  var setState = match[1];
  var state = match[0];
  var onUnmute = function (param) {
    Tone.startSynth(synth);
    return Curry._1(setState, (function (state) {
                  return {
                          userState: state.userState,
                          synthState: /* IsPlaying */0
                        };
                }));
  };
  var onMute = function (param) {
    Tone.stopSynth(synth);
    return Curry._1(setState, (function (state) {
                  return {
                          userState: state.userState,
                          synthState: /* IsNotPlaying */1
                        };
                }));
  };
  var onUserInteraction = function (param) {
    return Curry._1(setState, (function (state) {
                  return {
                          userState: /* HasInteracted */1,
                          synthState: state.synthState
                        };
                }));
  };
  var onPlayNote = function (note) {
    Tone.startSynth(synth);
    synth.triggerAttack(note);
    Curry._1(setState, (function (param) {
            return {
                    userState: /* HasInteracted */1,
                    synthState: /* IsPlaying */0
                  };
          }));
    
  };
  var match$1 = url.path;
  var tmp;
  tmp = match$1 ? (
      match$1.hd === "free" && !match$1.tl ? React.createElement(Free.make, {
              userState: state.userState,
              onUserInteraction: onUserInteraction,
              onMute: onMute,
              onUnmute: onUnmute,
              synthState: state.synthState,
              triggerAttack: (function (param) {
                  return Tone.triggerNote(synth, param);
                }),
              onUnmount: onMute
            }) : React.createElement("div", undefined)
    ) : React.createElement(Basic.make, {
          onPlayNote: onPlayNote,
          onStopNote: onMute,
          onUnmount: onMute,
          synthState: state.synthState
        });
  return React.createElement("main", {
              className: "flex flex-col items-center h-screen w-screen"
            }, React.createElement(Layout.make, {
                  children: tmp
                }));
}

var make = App;

exports.synth = synth;
exports.make = make;
/* synth Not a pure module */
