// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Constants = require("../Constants.bs.js");
var ReactSelect = require("react-select").default;

function ChangeTuning(Props) {
  var onChangeTuning = Props.onChangeTuning;
  return React.createElement(ReactSelect, {
              options: Constants.Tuning.tunings,
              onChange: onChangeTuning,
              placeholder: "Select tuning"
            });
}

var make = ChangeTuning;

exports.make = make;
/* react Not a pure module */
